import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, IonListHeader,
  IonItem, IonAccordionGroup, IonAccordion, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonLoading,
  IonHeader, IonToolbar, IonSearchbar, IonSegment, IonSegmentButton, IonToggle,  IonDatetime, IonModal, IonPopover, IonFabButton, IonFab, IonSpinner, IonItemDivider } from '@ionic/angular/standalone';
import { lastValueFrom } from 'rxjs';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { EntiteBilanFinancier, EntiteScenario } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';
import { __values } from 'tslib';

@Component({
  selector: 'app-bilan-financier',
  templateUrl: './bilan-financier.component.html',
  styleUrls: ['./bilan-financier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonItemDivider, IonSpinner, IonFab, IonFabButton, IonPopover, IonModal,
    IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonListHeader, IonItem, IonAccordionGroup, IonAccordion, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon,
    IonHeader, IonToolbar, IonSearchbar, IonSegment, IonSegmentButton, IonLoading,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonToggle, IonDatetime ]
})
export class BilanFinancierComponent  implements OnInit {
  @Input() i!: number;
  @Input() bilanFinancier!: EntiteBilanFinancier;
  
  userRoles : any;

  constructor( protected projet : ProjetPage, public ps: ProjetService, public auth : AuthentificationService) {  }

  ngOnInit() { 
    this.userRoles = this.auth.tokenInfoSignal()?.roles;
  }

  formatteNombre(valeur : number, precision: number){
    return this.ps.formatteNombre(<string><unknown>valeur, precision);
  }

  getParticipant(id : number){
    return this.projet.getParticipant(id);
  }

  indicateurBilanGlobal(indexScenario: number, indicateur: string, precision: number) {
    return this.projet.indicateurBilanGlobal(indexScenario, indicateur, precision);
  }

  indicateurBilanParticipant(indexScenario: number, idParticipant: number, indicateur: string, precision: number){
    return this.projet.indicateurBilanParticipant(indexScenario, idParticipant, indicateur, precision);
  }

  indicateurBilanFinancierParticipant(indexScenario: number, idParticipant: number, indicateur: string, precision: number){
    return this.projet.indicateurBilanFinancierParticipant(indexScenario, idParticipant, indicateur, precision);
  }
}
