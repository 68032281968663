@if (i >= 0 && projet.projetCourant && projet.projetCourant.scenarios[i].bilanFinancier.indicateurs){
<ion-card color="light" *ngFor="let prod of bilanFinancier.indicateurs;">
  <ion-card-header>
    <ion-card-title>{{ getParticipant(prod.id_participant)?.nom }}</ion-card-title>
  </ion-card-header>
  <ion-card-content >
    <ion-grid>
      <ion-row>
        <ion-col size="6"><!-- Principaux résultats énergétiques -->
          <ion-grid class="tableau-facturation"> 
            <ion-row class="bordure">
              <ion-col size="12" class="facturation-titre"><ion-title class="ion-text-center tableau-facturation" style="font-size:0.9em;">Principaux résultats énergétiques</ion-title></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>Installation solaire</strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Puissance crête installée</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanParticipant(i,  prod.id_participant, 'puissance_crete', 1) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kWc</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Puissance des onduleurs</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanParticipant(i, prod.id_participant, 'puissance_onduleur', 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kVA</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">Dégradation performance</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.parametres.degradation_performance, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">% / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>Bilan énergétique en autoconsommation</strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Puissance du générateur en autoconsommation</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanParticipant(i,  prod.id_participant, 'puissance_crete', 1) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kWc</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Energie PV produite</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.parametres.energie_pv_produite, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kWh / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Energie autoconsommée</ion-col>
              @if (getParticipant(prod.id_participant)?.consommateur == null){
                <ion-col size="3" class="tfd"><strong>NC</strong></ion-col>
                <ion-col size="3" class="tfg">(pas de conso)</ion-col>
              } @else {
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.parametres.energie_autoconsommee, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kWh / an</ion-col>
            }
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Energie excédentaire</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanGlobal(i, 'total_injection', 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kWh / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Taux d'autoconsommation</ion-col>
              @if (getParticipant(prod.id_participant)?.consommateur == null){
                <ion-col size="3" class="tfd"><strong>NC</strong></ion-col>
                <ion-col size="3" class="tfg">(pas de conso)</ion-col>
              } @else {
                <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.parametres.taux_autoconsommation, 2) }}</strong></ion-col>
                <ion-col size="3" class="tfg">%</ion-col>
              }
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Puissance excédentaire maximale</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanParticipant(i, prod.id_participant, 'excedent_max', 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kW</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Ratio Pexc/Pc</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanParticipant(i, prod.id_participant, 'part_excedent_max', 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">&nbsp;</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Consommation annuelle</ion-col>
              @if (getParticipant(prod.id_participant)?.consommateur == null){
                <ion-col size="3" class="tfd"><strong>NC</strong></ion-col>
                <ion-col size="3" class="tfg">(pas de conso)</ion-col>
              } @else {
                <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].consommation_energie, 0) }}</strong></ion-col>
                <ion-col size="3" class="tfg">kWh</ion-col>
              }
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Facture annuelle électricité (avec PV)</ion-col>
              @if (getParticipant(prod.id_participant)?.consommateur == null){
                <ion-col size="3" class="tfd"><strong>NC</strong></ion-col>
                <ion-col size="3" class="tfg">(pas de conso)</ion-col>
              } @else {
                  <ion-col size="3" class="tfd"><strong>{{ indicateurBilanFinancierParticipant(i, prod.id_participant, 'facture_avec_solaire', 2) }}</strong></ion-col>
                  <ion-col size="3" class="tfg">€ HTVA</ion-col>
              }
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Coût moyen électricité réseau</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ indicateurBilanFinancierParticipant(i, prod.id_participant, 'cout_moyen_energie', 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">c€ HTVA / kWh</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Valorisation énergie PV</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.parametres.valorisation_energie_pv, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">c€ HTVA / kWh</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">Hausse prix électricité</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.parametres.hausse_prix_electricite, 1) }}</strong></ion-col>
              <ion-col size="3" class="tfg">% / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col size="6">  <!-- Principaux résultats financiers -->
          <ion-grid class="tableau-facturation">
            <ion-row class="bordure">
              <ion-col size="12" class="facturation-titre"><ion-title class="ion-text-center tableau-facturation" style="font-size:0.9em;">Principaux résultats financiers</ion-title></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6"><strong>Produit d'exploitation en année 0 : </strong></ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].CA, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col class="tfg" size="12">dont :</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>Revenus liés à l'autoconsommation (€ HTVA / an)</strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Economies pour l'investisseur</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].economies_investisseur, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Vente prod solaire aux autoconsommateurs</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].vente_prod_solaire_aux_autoconsommateurs, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Prime à l'investissement</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.prime_investissement, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Vente d'excédent au tarif réglementé</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].vente_excedent_tarif_reglemente, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Vente d'excédent au tarif agrégateur</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].vente_excedent_tarif_aggregateur, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Complément de rémunération autoconso</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.details[0].complement_remuneration_autoconso, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col class="tfg" size="12"><strong>&nbsp; </strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">Autre revenu fixe</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.autre_revenu_fixe, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6"><!-- Bilan économique sur 30 ans -->
          <ion-grid class="tableau-facturation"> 
            <ion-row class="bordure">
              <ion-col size="12" class="facturation-titre"><ion-title class="ion-text-center tableau-facturation" style="font-size:0.9em;">Bilan économique sur {{ prod.indicateurs.parametres.duree_simulation }} ans</ion-title></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Energie produite</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.bilan_eco.energie_produite, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">kWh</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Durée d'amortissement</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.bilan_eco.duree_ammortissement, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">ans</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6"><strong>CAPEX net de subvention</strong></ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.bilan_eco.CAPEX_net_subvention, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg"><strong>€</strong></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">PEX - produits moyens</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.bilan_eco.PEX_moyen, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€ / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">CEX - charges moyennes</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.bilan_eco.CEX_moyen, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">LCOE PV sans aide financière</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.bilan_eco.LCOE, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">c€ / kWh</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">LCOE PV avec aide financière</ion-col>
              <ion-col size="3" class="tfd"><strong>NC</strong></ion-col>
              <ion-col size="3" class="tfg">c€ / kWh</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">LCOE électricité réseau</ion-col>
              <ion-col size="3" class="tfd"><strong>NC</strong></ion-col>
              <ion-col size="3" class="tfg">c€ / kWh</ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col size="6">  <!-- Résultats sur l'investissement -->
          <ion-grid class="tableau-facturation">
            <ion-row class="bordure">
              <ion-col size="12" class="facturation-titre"><ion-title class="ion-text-center tableau-facturation" style="font-size:0.9em;">Résultats sur l'investissement</ion-title></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">Taux d'actualisation</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.taux_actualisation, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">% / an</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">CA actualisé sur {{ prod.indicateurs.parametres.duree_simulation }} ans</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.CA_actualise, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6">Résultat avant impôt sur {{ prod.indicateurs.parametres.duree_simulation }} ans</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.resultat_avant_impots, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">TRB (Temps de Retour Brut)</ion-col>
              <ion-col size="3" class="tfd" *ngIf="prod.indicateurs.ROI.TRB >= 0"><strong>{{ formatteNombre(prod.indicateurs.ROI.TRB, 1) }}</strong></ion-col>
              <ion-col size="3" class="tfd" *ngIf="0 > prod.indicateurs.ROI.TRB"><strong>Non convergent</strong></ion-col>
              <ion-col size="3" class="tfg">ans</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6">TRI (Taux de Rentabilité Interne)</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.TRI, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">%</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd">
              <ion-col size="6"><strong>VAN</strong> (Valeur Actuelle Nette)</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.VAN, 0) }}</strong></ion-col>
              <ion-col size="3" class="tfg">€</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6"><strong>TRA</strong> (Temps de Retour Actualisé)</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.TRA, 1) }}</strong></ion-col>
              <ion-col size="3" class="tfg">ans</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b">
              <ion-col size="6"><strong>TEC</strong> (Taux d'enrichissement du Capital)</ion-col>
              <ion-col size="3" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.ROI.TEC, 2) }}</strong></ion-col>
              <ion-col size="3" class="tfg">&nbsp;</ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
      <ion-row class="noHover">
        <ion-col size="12"><!-- Tableau des 30 ans -->
          <ion-grid class="tableau-facturation"> 
            <ion-row class="bordure">
              <ion-col  class="facturation-titre" ><ion-title class="ion-text-center tableau-facturation" style="font-size:0.9em;">Détails annuels</ion-title></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center bord-gd bord-b noHover">
              <ion-col size="3.5" class="tfg" style="padding: 0 0 17px 0; ">
                <ion-grid>
                  <ion-row class="noHover"><ion-col class="tfg">Année fiscale</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Production d'énergie AC (kWh)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Consommation d'énergie (kWh)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Energie autoconsommée (kWh)</ion-col></ion-row>
                  @if (userRoles.includes('ROLE_DEV')){
                    <ion-row class="noHover"><ion-col class="tfg">Production injectée dans l'ACC</ion-col></ion-row>
                    <ion-row class="noHover"><ion-col class="tfg">Excedent de production injecté dans le réseau</ion-col></ion-row>
                  }
                  <ion-row class="noHover"><ion-col class="tfg">Tarif d'achat vente du surplus (c€/kWh)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Tarif d'achat vente après plafond ({{ prod.indicateurs.parametres.plafond_production}} heures)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">&nbsp;</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Economies pour l'investisseur (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Vente aux autoconsommateurs (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Prime à l'investissement (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Vente d'excédent au tarif reglementé (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Vente d'excédent au tarif agrégateur (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Complément de rémunération (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Autre revenu fixe (€)</ion-col></ion-row>
                  <ion-row class="noHover"><ion-col class="tfg">Chiffre d'affaire (€)</ion-col></ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="8.5" style="padding: 5px 5px 5px 0; ">
                <ion-grid class="tabHorizontal">
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfc" ><strong>CUMUL</strong></ion-col>
                    <ion-col size="1" class="tfc" >% CA</ion-col>
                    <ion-col size="1" class="tfc"*ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.an, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.production_denergie_ac, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfg">&nbsp;</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.production_denergie_ac, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>
                      @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(prod.indicateurs.cumul.consommation_energie, 0) }}}
                      </strong></ion-col>
                    <ion-col size="1" class="tfd">&nbsp;</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">
                      @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(ligne.consommation_energie, 0) }}}
                    </ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>
                      @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(prod.indicateurs.cumul.energie_autoconsommee, 0) }}}
                    </strong></ion-col>
                    <ion-col size="1" class="tfd">&nbsp;</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">
                      @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(ligne.energie_autoconsommee, 0) }}}</ion-col>
                  </ion-row>

                  @if (userRoles.includes('ROLE_DEV')){
                    <ion-row class="ion-align-items-center noHover">
                      <ion-col size="2" class="tfd"><strong>
                        @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(prod.indicateurs.cumul.energie_acc, 0) }}}
                      </strong></ion-col>
                      <ion-col size="1" class="tfd">&nbsp;</ion-col>
                      <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">
                        @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(ligne.energie_acc, 0) }}}</ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center noHover">
                      <ion-col size="2" class="tfd"><strong>
                        @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(prod.indicateurs.cumul.excedent, 0) }}}
                      </strong></ion-col>
                      <ion-col size="1" class="tfd">&nbsp;</ion-col>
                      <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">
                        @if (getParticipant(prod.id_participant)?.consommateur == null){-} @else {{{ formatteNombre(ligne.excedent, 0) }}}</ion-col>
                    </ion-row>
                  }

                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>&nbsp;</strong></ion-col>
                    <ion-col size="1" class="tfd">&nbsp;</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.tarif_achat_vente_surplus, 2) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>&nbsp;</strong></ion-col>
                    <ion-col size="1" class="tfd">&nbsp;</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.tarif_achat_vente_surplus_minore, 2) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col class="tfg"><strong>&nbsp; </strong></ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.economies_investisseur, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.economies_investisseur, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.economies_investisseur, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.vente_prod_solaire_aux_autoconsommateurs, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.vente_prod_solaire_aux_autoconsommateurs, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.vente_prod_solaire_aux_autoconsommateurs, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.prime_investissement, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.prime_investissement, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.prime_investissement, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.vente_excedent_tarif_reglemente, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.vente_excedent_tarif_reglemente, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.vente_excedent_tarif_reglemente, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.vente_excedent_tarif_aggregateur, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.vente_excedent_tarif_aggregateur, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.vente_excedent_tarif_aggregateur, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.complement_remuneration_autoconso, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.complement_remuneration_autoconso, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.complement_remuneration_autoconso, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.autre_revenu_fixe, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">{{ formatteNombre(prod.indicateurs.cumul_pc.autre_revenu_fixe, 2) }} %</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.autre_revenu_fixe, 0) }}</ion-col>
                  </ion-row>
                  <ion-row class="ion-align-items-center noHover">
                    <ion-col size="2" class="tfd"><strong>{{ formatteNombre(prod.indicateurs.cumul.CA, 0) }}</strong></ion-col>
                    <ion-col size="1" class="tfd">100%</ion-col>
                    <ion-col size="1" class="tfd" *ngFor="let ligne of prod.indicateurs.details">{{ formatteNombre(ligne.CA, 0) }}</ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
}